export function customListResponse(list: any, pageSize: number, page: number) {
  let inicial = (page - 1) * pageSize;
  let final = inicial + pageSize;

  let data = list.slice(inicial, final);
  let count = list.length;

  return {
    data,
    metadata: {
      count,
      page,
      perPage: pageSize,
      totalPages: Math.ceil(count / pageSize),
    },
  };
}
