import { Injectable } from '@angular/core';
import { UtilsService } from '../service/utils.service';
import { DatabaseService } from './database.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SystemConfiguration, SystemConfigurationType } from './models/system-configuration.models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemConfigurationService {
  constructor(
    protected onlineService: OnlineService,
    protected offlineService: OfflineService,
    protected databaseService: DatabaseService,
    private http: HttpClient,
    public utils: UtilsService
  ) {}

  getHelp() {
    if (this.utils.isOnline()) {
      return this.onlineService.getHelp();
    } else {
      return this.offlineService.getHelp();
    }
  }

  getPrivacyPolicy() {
    if (this.utils.isOnline()) {
      return this.onlineService.getPrivacyPolicy();
    } else {
      return this.offlineService.getPrivacyPolicy();
    }
  }

  deleteAndInsertSystemConfigurations(data: SystemConfiguration[]) {
    return this.databaseService.db.systemConfiguration
      .where('type')
      .anyOf([SystemConfigurationType.PRIVACY, SystemConfigurationType.HELP_PWA])
      .delete()
      .then(() => {
        this.databaseService.db.systemConfiguration.bulkPut(data);
      });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OnlineService {
  constructor(protected databaseService: DatabaseService, private http: HttpClient, public utils: UtilsService) {}

  getHelp() {
    return new Observable((obs) => {
      let params = new HttpParams();

      const type = SystemConfigurationType.HELP_PWA;

      let uri = `${environment.API_URL}/api/system-configuration/lastestVersion/${type}`;
      return this.http.get<any[]>(uri, { params }).subscribe(
        (res: any) => {
          let resp = res.data?.[0] ?? null;

          if (!resp) {
            obs.error();
            obs.complete();
            return;
          }

          // ! save locally

          obs.next(resp);
          obs.complete();
        },
        (error: any) => {
          console.warn(`SystemConfigurationService ~ getPrivacyPolicy:`, error);
          obs.error();
          obs.complete();
        }
      );
    });
  }

  getPrivacyPolicy() {
    return new Observable((obs) => {
      let params = new HttpParams();

      const type = SystemConfigurationType.PRIVACY;

      let uri = `${environment.API_URL}/api/system-configuration/lastestVersion/${type}`;
      return this.http.get<any[]>(uri, { params }).subscribe(
        (res: any) => {
          let resp = res.data?.[0] ?? null;

          if (!resp) {
            obs.error();
            obs.complete();
            return;
          }

          // ! save locally

          obs.next(resp);
          obs.complete();
        },
        (error: any) => {
          console.warn(`SystemConfigurationService ~ getPrivacyPolicy:`, error);
          obs.error();
          obs.complete();
        }
      );
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  constructor(protected databaseService: DatabaseService, public utils: UtilsService) {}

  getHelp() {
    return new Observable((obs) => {
      return this.databaseService.db.systemConfiguration
        .where({ type: SystemConfigurationType.HELP_PWA })
        .toArray()
        .then((res: SystemConfiguration[]) => {
          obs.next(res[0] ?? null);
        })
        .catch((error: any) => {
          console.warn(`SystemConfigurationService ~ getPwaHelp:`, error);
          obs.error(error);
        });
    });
  }

  getPrivacyPolicy() {
    return new Observable((obs) => {
      return this.databaseService.db.systemConfiguration
        .where({ type: SystemConfigurationType.PRIVACY })
        .toArray()
        .then((res: SystemConfiguration[]) => {
          obs.next(res[0] ?? null);
        })
        .catch((error: any) => {
          console.warn(`SystemConfigurationService ~ getPrivacyPolicy:`, error);
          obs.error(error);
        });
    });
  }
}
