import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { Company } from './models/company.models';
import { UserCompany } from './models/userCompany.models';

@Injectable({
  providedIn: 'root',
})
export class UserCompaniesService {
  constructor(protected databaseService: DatabaseService) {}

  getUserCompanies(userId: string): Company[] {
    return this.databaseService.db.userCompanies
      .where({ userId })
      .with({ company: 'companyId' })
      .then((res: UserCompany[]) => {
        return res.map((r: UserCompany) => r.company);
      });
  }

  getUserCompany(userId: string, companyId: string): UserCompany | null {
    return this.databaseService.db.userCompanies
      .where({ userId, companyId })
      .with({ company: 'companyId' })
      .then((res: UserCompany[]) => {
        return res[0] ?? null;
      });
  }

  deleteAndInsertCompanies(userId: string, companies: any[]) {
    return this.databaseService.db.userCompanies
      .where({ userId })
      .delete()
      .then(() => {
        return this.databaseService.db.userCompanies.bulkPut(companies);
      });
  }
}
