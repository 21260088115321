export class SystemConfiguration {
  public id!: string;
  public version!: number;
  public text!: string;
  public type!: SystemConfigurationType;
}

export enum SystemConfigurationType {
  PRIVACY = 'PRIVACY',
  HELP_PWA = 'HELP_PWA',
  HELP_BO = 'HELP_BO',
}
