import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          const ignore401 = request.headers.has('ignore401');
          if (!ignore401 && !this.isRecoveryPassword()) {
            // auto logout if 401 response returned from api
            this.authService.logout().subscribe();
            this.router.navigate(['auth/auth-home']);
            //location.reload();
          }
        }

        let error = err?.error?.errors?.[0]?.error ?? err?.error?.error ?? err.statusText ?? '';

        console.error(`eGuias App ~ error:`, error);
        return throwError(error);
      })
    );
  }

  isRecoveryPassword() {
    return ['/auth/recoveryPassword/', '/auth/recoveryPin/'].some((path) => window.location.href.includes(path));
  }
}
