<div class="app-header">
  <nav class="nav-extended topbar">
    <div class="nav-wrapper">
      <a id="sidenav-trigger" data-target="sidenav-trigger" class="sidenav-trigger" (click)="onSidenavOpen()">
        <span class="mdi mdi-menu"></span>
      </a>
      <div class="logo-wrapper">
        <img class="logo clickable" src="assets/logo/antram-light-logo.png" (click)="goToHome()" />
      </div>
      <div class="actions-wrapper">
        <!-- <img class="logo" src="assets/logo/xGo_logo.jpg" /> -->
        <span class="offline-icon mdi mdi-wifi-off" *ngIf="!isOnline"></span>
      </div>
    </div>
  </nav>

  <ul #slideOut class="sidenav">
    <li *ngIf="currentUser">
      <div class="user-view">
        <div class="background">
          <img class="menu-background" src="assets/images/menu-background.jpeg" />
        </div>
        <a><img class="circle" [src]="currentUser.photo" (click)="onProfile()" /></a>
        <a>
          <span class="name white-text"> {{ currentUser.name }}</span>
        </a>
        <a>
          <span class="email white-text">{{ currentUser.email }}</span>
        </a>
        <a>
          <span class="company white-text">Empresa: {{ currentCompany?.name }}</span>
        </a>
      </div>
    </li>
    <li>
      <a class="sidenav-line" (click)="onHome()"><span class="mdi mdi-home"></span>&nbsp;Início</a>
    </li>
    <li>
      <a class="sidenav-line" (click)="onProfile()">
        <span class="mdi mdi-account"></span>&nbsp;Perfil de Utilizador
      </a>
    </li>
    <li>
      <a
        class="sidenav-line"
        (click)="onChangeCompany()"
        *ngIf="currentUser?.companyUsers && (currentUser?.companyUsers)!.length > 1"
      >
        <span class="mdi mdi-office-building"></span>&nbsp;Alterar Empresa
      </a>
    </li>
    <li>
      <a class="sidenav-line" (click)="onGuides()"> <span class="mdi mdi-history"></span>&nbsp;Guias </a>
    </li>
    <li>
      <a class="sidenav-line" (click)="onHelpPage()"> <span class="mdi mdi-help-circle"></span>&nbsp;Ajuda </a>
    </li>
    <li>
      <a class="sidenav-line" (click)="onPrivacyPolicy()">
        <span class="mdi mdi-script-text"></span>&nbsp;Política de Privacidade
      </a>
    </li>
    <li>
      <div class="divider"></div>
    </li>
    <li>
      <a class="sidenav-line" (click)="onLogout()"> <span class="mdi mdi-logout"></span>&nbsp;Terminar Sessão </a>
    </li>
    <div class="eguias-version">
      <div>Guias v.{{ version }}</div>
      <div>Desenvolvido por XpertGo</div>
    </div>
  </ul>
</div>

<div class="app-content">
  <router-outlet></router-outlet>
</div>

<ng-template #customLoadingTemplate>
  <div *ngIf="showLoadingMessage" class="custom-class">
    <p>A atualizar dispositivo. Aguarde!</p>
  </div>
</ng-template>

<div class="install-banner" *ngIf="showInstallBanner">
  <div class="app-logo-container">
    <img class="img-logo" src="assets/logo/xGo_logo.jpg" />
    <span class="install-text">Instalar Aplicação no Dispositivo?</span>
  </div>
  <div class="install-button-container">
    <a class="btn" (click)="onInstallPwa()">Instalar</a>
    <a class="not-now-button" (click)="onRefusePwa()">Agora não</a>
  </div>
</div>

<!-- <ngx-loading class="loading" [show]="syncLoading" [template]="customLoadingTemplate"></ngx-loading> -->
