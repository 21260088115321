import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private internalConnectionChanged = new Subject<boolean>();

  constructor(public utils: UtilsService) {
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }

  private updateOnlineStatus() {
    this.internalConnectionChanged.next(this.utils.isOnline());
  }
}
