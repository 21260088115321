import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from '../database/user.service';
import { UtilsService } from './utils.service';
import * as bcrypt from 'bcryptjs';
import { UserCompaniesService } from '../database/user-companies.service';
import { CompanyService } from '../database/company.service';
import { User } from '../database/models/auth.models';
import { GuideToken } from '../database/models/guideToken.models';
import { BehaviorSubject, catchError, finalize, Observable, of, Subject } from 'rxjs';
import { Company } from '../database/models/company.models';
import { UserCompany } from '../database/models/userCompany.models';
import { DatabaseService } from '../database/database.service';
import { GuideService } from '../database/guide.service';
import moment from 'moment';
import { Update } from '../database/models/update.models';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  handleError: any;
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  isRefreshing = false;
  $isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  user: User | null = null;
  company: Company | null = null;

  constructor(
    protected userService: UserService,
    protected companyService: CompanyService,
    protected userCompaniesService: UserCompaniesService,
    protected databaseService: DatabaseService,
    protected guideService: GuideService,
    private router: Router,
    private http: HttpClient,
    public utils: UtilsService
  ) {
    this.availableGuides = Number(localStorage.getItem('availableGuides') ?? 0);
  }

  get currentUser(): User | null {
    if (!this.user) {
      this.user = JSON.parse(localStorage.getItem('currentUser')!);
    }
    return this.user;
  }
  set currentUser(user: User | null) {
    this.user = user;
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  private companyChanged = new Subject<Company | null>();
  get companyChangedListenner() {
    return this.companyChanged.asObservable();
  }
  get currentCompany(): Company | null {
    if (!this.company) {
      this.company = JSON.parse(localStorage.getItem('currentCompany')!);
    }
    return this.company;
  }
  set currentCompany(company: Company | null) {
    this.company = company;
    localStorage.setItem('currentCompany', JSON.stringify(company));
    this.companyChanged.next(this.company);
  }

  isLoggedIn() {
    const token = localStorage.getItem('token');

    if (!token) {
      return false;
    }

    const payload = atob(token.split('.')[1]);
    const parsedPayload = JSON.parse(payload);

    return parsedPayload.exp > Date.now() / 1000;
  }

  login(email: string, password: string): any {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let uri = '';
    let authData = {};

    if (emailRegex.test(email)) {
      uri = `${environment.API_URL}/api/auth/login`;
      authData = { email, password };
    }

    const vatRegex = /^\d{9}$/;
    if (vatRegex.test(email)) {
      uri = `${environment.API_URL}/api/auth/nif-login`;
      authData = { nif: email, pin: password };
    }

    if (uri == '') {
      this.utils.toast('Email/NIF Inválidos');
      return null;
    }

    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    return new Promise((resolve, reject) => {
      this.http
        .post<any>(uri, authData, {
          headers: this.headers.append('no-token', ''),
        })
        .subscribe(
          async (res: any) => {
            await this.onSuccessLogin(res);
            return true;
          },
          (error: any) => {
            console.warn(`AuthService ~ login:`, error);

            if (error.error?.errors?.length > 0) {
              this.utils.toast(error.error?.errors[0].error);
              if (error.error?.errors[0].message == 'AUTH_ACTIVATE_NIF_LOGIN') {
                resolve(error.error?.errors[0].message);
              } else {
                resolve(null);
              }
            } else {
              this.utils.toast('Ocorreu um erro na autenticação, por favor tente outra vez.');
              resolve(null);
            }
          }
        );
    });
  }

  loginPin(userId: any, pin: any) {
    return new Observable((obs) => {
      if (!this.utils.isOnline()) {
        this.utils.toast('Não existe uma conexão à internet');
        obs.error();
        obs.complete();
        return;
      }

      let data = { userId, pin };

      let uri = `${environment.API_URL}/api/auth/pin-login`;
      return this.http
        .post<any>(uri, data, { headers: this.headers.append('no-token', '').append('ignore401', '') })
        .subscribe(
          async (res: any) => {
            await this.onSuccessLogin(res);
            obs.next();
            obs.complete();
          },
          (error: any) => {
            this.utils.toast('Ocorreu um erro na autenticação, por favor tente outra vez.');
            obs.error();
            obs.complete();
          }
        );
    });
  }

  async onSuccessLogin(res: any) {
    return new Promise(async (resolve, reject) => {
      this.setRefreshingToken(false);

      let result = res.data?.[0] ?? null;

      if (!result) {
        this.utils.toast('Login inválido');
        return resolve(false);
      }

      result.expiresIn = new Date(Date.now() + result.expiresIn);
      // result.expiresIn = new Date(Date.now() + 3 * 60_000);

      localStorage.setItem('token', result.accessToken);
      localStorage.setItem('expiresIn', result.expiresIn);

      await this.setCurrentUser(result.payload);

      if (result.payload.companyUsers.length === 1) {
        this.currentCompany = result.payload.companyUsers[0].company;
        // await this.setActiveCompany(result.payload.id, result.payload.companyUsers[0].company?.id);
        this.router.navigate(['home']);
        return resolve(true);
      }
      if (result.payload.companyUsers.length > 1) {
        this.router.navigate(['select-company']);
        return resolve(true);
      }

      return resolve(true);
    });
  }

  async loginPinOffline(userId: string, pin: string) {
    const user = await this.userService.findOne(userId);

    if (!user) {
      return;
    }

    const hashPin = await bcrypt.hash(`${pin}${userId}`, user.salt);

    if (user.hashPin !== hashPin) {
      this.utils.toast('Pin inválido');
      return null;
    }

    localStorage.setItem('token', user.token!);
    this.currentUser = user;

    const userCompanies = await this.userCompaniesService.getUserCompanies(userId);

    if (!userCompanies || userCompanies.length == 0) {
      this.utils.toast('Utilizador não tem nenhuma empresa associada');
      return null;
    }
    if (userCompanies.length === 1) {
      this.currentCompany = userCompanies[0];
      return this.router.navigate(['home']);
    }
    if (userCompanies.length > 1) {
      return this.router.navigate(['select-company']);
    }

    return;
  }

  initialRefreshToken(): Observable<any> {
    return this.refreshToken().pipe(catchError(() => of(true)));
  }

  refreshToken(): Observable<any> {
    return new Observable((obs) => {
      this.setRefreshingToken(true);

      return this.http
        .post<any>(
          `${environment.API_URL}/api/auth/refreshToken`,
          {},
          {
            headers: { 'no-error': 'true', 'no-token': 'true' },
            withCredentials: true,
          }
        )
        .pipe(finalize(() => this.setRefreshingToken(false)))
        .subscribe(
          async (res) => {
            let result = res.data?.[0] ?? null;

            if (!result) {
              obs.error();
              obs.complete();
              return;
            }

            result.expiresIn = new Date(Date.now() + result.expiresIn - 2 * 60_000);
            // result.expiresIn = new Date(Date.now() + 3 * 60_000);

            localStorage.setItem('token', result.accessToken);
            localStorage.setItem('expiresIn', result.expiresIn);

            await this.setCurrentUser(result.payload);

            obs.next(res);
            obs.complete();
          },
          (error: any) => {
            obs.error(error);
            obs.complete();
          }
        );
    });
  }

  private setRefreshingToken(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
    this.$isRefreshing.next(this.isRefreshing);
  }

  validateAccount(code: string, password: string, pin: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { code, password, pin };

    let uri = `${environment.API_URL}/api/auth/verification`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  async setCurrentUser(user: any) {
    return new Promise(async (resolve, reject) => {
      if (!user.photo || user.photo == null || user.photo.trim == '') {
        user['photo'] = this.utils.getInitialsAvatar(user.name);
      }

      this.currentUser = user;

      await this.userService.createOrUpdate(user);

      for (const cu of user.companyUsers) {
        const company = new Company(cu.company);
        await this.companyService.createOrUpdate(company);
      }

      await this.userCompaniesService.deleteAndInsertCompanies(user.id, user.companyUsers);

      if (user.companyUsers.length === 0) {
        this.router.navigate(['/auth/auth-home']);
        return resolve(false);
      }

      let currentCompany = JSON.parse(localStorage.getItem('currentCompany')!);
      if (!currentCompany) {
        return resolve(true);
      }

      let payloadCompany = user.companyUsers.find((cu: UserCompany) => cu.company?.id === currentCompany.id);
      if (!payloadCompany) {
        this.router.navigate(['/auth/auth-home']);
        return resolve(false);
      }

      return resolve(true);
    });
  }

  pinVerification(nif: string, code: string, pin: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { nif, activationPin: code, pin };

    let uri = `${environment.API_URL}/api/auth/pinVerification`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  passwordRecovery(email: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { email };

    let uri = `${environment.API_URL}/api/auth/passwordRecovery`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  resetPassword(code: string, password: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { code, password };

    let uri = `${environment.API_URL}/api/auth/passwordReset`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  pinRecovery(email: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { email };

    let uri = `${environment.API_URL}/api/auth/pinRecovery`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  resetPin(code: string, pin: string) {
    if (!this.utils.isOnline()) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = { code, pin };

    let uri = `${environment.API_URL}/api/auth/pinReset`;
    return this.http.post<any>(uri, data, { headers: this.headers.append('no-token', '') });
  }

  logout() {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/auth/logout`,
        {},
        {
          headers: new HttpHeaders().append('no-token', 'true'),
          withCredentials: true,
        }
      )
      .pipe(
        finalize(() => {
          this.router.navigate(['auth/auth-home']);

          localStorage.removeItem('token');
          localStorage.removeItem('expiresIn');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('currentCompany');
          localStorage.removeItem('availableGuides');

          this.currentUser = null;
          this.currentCompany = null;

          const cookieName = `tokenRefresh${environment.API_URL.replace(/[\W_]+/g, '')}`;
          document.cookie = `${cookieName}=; max-age=0; path=/;`;
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        })
      );
  }

  guideToken: any = null;
  async validateGuideToken(guideId: string, guideToken: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let uri = `${environment.API_URL}/api/guide/${guideId}/checkAccess?guideToken=${guideToken}`;
      return this.http.get<any>(uri, {}).subscribe(
        (res: any) => {
          if (!res.data.length) {
            resolve(false);
            return;
          }

          this.guideToken = new GuideToken({ ...res.data[0] });
          resolve(true);
        },
        (error: any) => {
          console.warn(`GuideFormComponent ~ validateGuideToken:`, error);
          resolve(false);
        }
      );
    });
  }

  async setActiveCompany(userId: string, companyId: string) {
    return new Promise(async (resolve, reject) => {
      const userCompany = await this.userCompaniesService.getUserCompany(userId, companyId);

      if (!userCompany?.company) {
        reject();
        return;
      }

      // this.currentCompany = userCompany.company;
      if (!this.currentCompany || this.currentCompany.id !== userCompany.company.id) {
        this.currentCompany = userCompany.company;
      }

      resolve(true);
    });
  }

  // SYNC DATA

  syncLoading: boolean = false;

  _availableGuides: number = 0;
  private availableGuidesChange = new Subject<number>();
  get availableGuidesListener() {
    return this.availableGuidesChange.asObservable();
  }
  get availableGuides(): number {
    if (this._availableGuides == null) {
      this.availableGuides = Number(localStorage.getItem('availableGuides') ?? 0);
    }
    return this._availableGuides;
  }
  set availableGuides(value: number) {
    this._availableGuides = value;
    localStorage.setItem('availableGuides', JSON.stringify(value));
    this.availableGuidesChange.next(this._availableGuides);
  }

  async syncData() {
    const userId = this.currentUser?.id;
    const companyId = this.currentCompany?.id;

    if (!this.utils.isOnline() || !userId || !companyId || this.syncLoading) {
      return;
    }

    console.groupCollapsed('SYNC');

    this.syncLoading = true;
    console.log(`START: ${moment().format('HH:mm:ss.SSS')}`);

    let lastUpdate = (await this.getLastestUpdate(userId, companyId))?.date ?? null;

    let syncData: any = { userId, companyId, lastUpdate };

    if (!syncData.lastUpdate) {
      delete syncData.lastUpdate;
    }

    return new Promise((resolve) => {
      let uri = `${environment.API_URL}/api/sync-guides`;
      this.http.post<any>(uri, syncData, { headers: this.headers }).subscribe(
        async (res: any) => {
          const data = res.data?.[0] ?? null;

          if (!data) {
            this.syncLoading = false;
            console.log(`END: ${moment().format('HH:mm:ss.SSS')}`);
            console.groupEnd();
            resolve(null);
            return;
          }

          if (data.user) {
            await this.setCurrentUser(data.user);
          }

          this.availableGuides = data?.availableGuides ?? 0;

          let { guides, notes, reports } = data.guides.reduce(
            (final: any, curr: any) => {
              final.guides.push({ ...curr, userId, companyId });

              final.notes = [...final.notes, ...curr.notes];

              const buf = Buffer.from(curr.report);
              const blob = new Blob([buf]);
              final.reports.push({ id: curr.id, guideId: curr.id, file: blob });

              return final;
            },
            { guides: [], notes: [], reports: [] }
          );

          await this.guideService.saveGuideLocally(guides);
          await this.guideService.saveGuideReportLocally(reports);

          this.createUpdate(userId!, companyId!).then(() => {
            this.syncLoading = false;
            console.log(`END: ${moment().format('HH:mm:ss.SSS')}`);
            console.groupEnd();
            resolve(res);
            return;
          });
        },
        (error: any) => {
          console.warn(`AppService ~ sync:`, error);
        }
      );
    });
  }

  getLastestUpdate(userId: string, companyId: string): Update {
    const db = this.databaseService.db;

    let where: any = {
      userId,
      companyId,
    };

    return db.update
      .where(where)
      .toArray()
      .then((res: Update[]) => {
        if (res.length > 0) {
          const r = this.utils.sortArrayByProperty(res, 'date', 'DESC');
          return r[0];
        } else {
          return null;
        }
      });
  }

  createUpdate(userId: string, companyId: string) {
    const db = this.databaseService.db;

    return db
      .transaction('rw', db.update, () => {
        return db.update.add({ userId, companyId, date: new Date() });
      })
      .catch((error: any) => {
        console.warn(`AppService ~ createUpdate:`, error);
      });
  }

  get allowGuides() {
    if (!this.currentUser || !this.currentCompany) {
      return false;
    }
    if (!this.utils.isOnline()) {
      return false;
    }
    if (!this.availableGuides) {
      return false;
    }
    return true;
  }
}
