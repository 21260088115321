import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  deferredPrompt: any;
  showInstallBanner: boolean = false;

  constructor(private platform: Platform) {
    if (environment.production) {
      this.initPwaPrompt();
    }
  }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.showInstallBanner = true;
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
      if (!isInStandaloneMode) {
        this.showInstallBanner = true;
      }
    }
  }
}
