import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../service/utils.service';
import { DatabaseService } from './database.service';
import { UserCompaniesService } from './user-companies.service';
import { User } from './models/auth.models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    protected userCompanyService: UserCompaniesService,
    protected databaseService: DatabaseService,
    private router: Router,
    private http: HttpClient,
    public utils: UtilsService
  ) {}

  findAll(): User[] {
    return this.databaseService.db.user.with({}).then(async (res: User[]) => {
      return res;
    });
  }

  findOne(id: string): User {
    return this.databaseService.db.user
      .where({ id })
      .toArray()
      .then(
        async (res: User[]) => {
          return res[0] ?? null;
        },
        (error: any) => {
          console.warn(`UserService ~ findOne:`, error);
        }
      );
  }

  createOrUpdate(data: User) {
    return this.databaseService.db.user.update(data.id, data).then((updated: any) => {
      if (updated) {
        return;
      }

      return this.databaseService.db.user.add(data).then(() => {
        return { status: 'SUCCESS' };
      });
    });
  }

  update(userId: string, user: any) {
    if (!user.photo || user.photo == null || user.photo.trim == '') {
      user['photo'] = this.utils.getInitialsAvatar(user.name);
    }

    return this.databaseService.db.user.update(userId, user);
  }

  delete(id: string): Promise<any> {
    return this.databaseService.db.user
      .where({ id })
      .delete()
      .then(() => {
        return this.databaseService.db.update
          .where({ userId: id })
          .delete()
          .then(() => {
            return {
              status: 'SUCCESS',
            };
          });
      });
  }

  acceptPrivacyPolicy(userId: string) {
    return this.databaseService.db.user
      .update(userId, {
        privacyPolicyAccepted: true,
        privacyDate: new Date(),
      })
      .then((updated: any) => {
        return {
          status: 'SUCCESS',
        };
      });
  }

  changePassword(password: string) {
    if (!this.utils.isOnline) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    let data = {
      password,
    };

    let uri = `${environment.API_URL}/api/user/password`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  changePin(pin: string) {
    if (!this.utils.isOnline) {
      this.utils.toast('Não existe uma conexão à internet');
      return null;
    }

    const data = {
      pin,
    };

    let uri = `${environment.API_URL}/api/user/pin`;
    return this.http.patch<any>(uri, data, { headers: this.headers });
  }

  updateOnlineUserInfo(token: string) {
    let params = new HttpParams().appendAll({
      relations: ['companyUsers'].join(),
    });

    let uri = `${environment.API_URL}/api/user/profile`;
    return this.http.get<any[]>(uri, { params, headers: { Authorization: `Bearer ${token}` } });
  }
}
