import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import relationships from 'dexie-relationships';
import { DatabaseSeedsService } from './database-seeds.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends Dexie {
  db: any;

  constructor(readonly databaseSeedsService: DatabaseSeedsService) {
    super('ngdexieliveQuery');

    this.initDb();
  }

  private initDb() {
    this.db = new Dexie(environment.DB, { addons: [relationships] });

    this.db.version(1).stores({
      user: [
        'id',
        'name',
        'email',
        'nif',
        'photo',
        'hashPin',
        'salt',
        'token',
        'expiresIn',
        'privacyPolicyAccepted',
        'privacyDate',
        'companyId -> company.id',
        'category',
        'updateDate',
      ].join(),
      userCompanies: [
        'id',
        'userId -> user.id',
        'companyId -> company.id',
        'name',
        'birthDate',
        'userCategoryId',
        'workStartDate',
        'address',
        'postalCode',
        'contact',
        'active',
      ].join(),
      company: [
        'id',
        'nif',
        'name',
        'email',
        'contact',
        'address',
        'locality',
        'postalCode',
        'countryId -> country.id',
        'isShipper',
        'isTransporter',
        'rittSubscribed',
        'eGuidesSubscribed',
        'status',
        'isDemo',
      ].join(),
      guide: [
        'id',
        'userId -> user.id',
        'companyId -> company.id',
        'guide_number',
        'internal_reference',
        'expeditor_nif',
        'expeditor_name',
        'expeditor_email',
        'expeditorCountryId  -> country.id',
        'expeditor_address',
        'expeditor_postalCode',
        'expeditor_locality',
        'transporter_nif',
        'transporter_name',
        'transporter_email',
        'transporterCountryId -> country.id',
        'transporter_addreess',
        'transporter_postalCode',
        'transporter_locality',
        'transporter_licenseNumber',
        'addressee_nif',
        'addressee_name',
        'addressee_email',
        'addresseeCountryId  -> country.id',
        'addressee_addreess',
        'addressee_postalCode',
        'addressee_locality',
        'loading_place',
        'unloading_place',
        'at_code',
        'special_conditions',
        'successive_transports_active',
        'successiveTransports',
        'goodsTransported',
        'dangerous_goods_active',
        'dangerousGoods',
        'dispatcher_instructions',
        'dispatcher_signature',
        'dispatcher_absent',
        'dispatcher_made_in',
        'dispatcher_date',
        'transport_price_dispatcher',
        'transport_price_addressee',
        'discounts_dispatcher',
        'discounts_addressee',
        'net_price_dispatcher',
        'net_price_addressee',
        'supplements_dispatcher',
        'supplements_addressee',
        'expenses_dispatcher',
        'expenses_addressee',
        'total_dispatcher',
        'total_addressee',
        'shipper_vehicle',
        'shipper_trailer',
        'shipper_gross_weight',
        'shipper_payload',
        'shipper_notes',
        'shipper_signature',
        'shipper_fuel_price',
        'shipper_notes_accepted',
        'shipperNotes',
        'receipt_of_goods_notes',
        'receipt_of_goods_signature',
        'receipt_of_goods_absent_addressee',
        'receipt_of_goods_place_date',
        'receipt_of_goods_notes_accepted',
        'receipt_of_goods_file_id -> file.id',
        'invoiceNumber',
        'invoiceDate',
        'status',
        'issued_at',
        'emmited_at',
        'createdAt',
        'updatedAt',
        'notes',
        'history',
      ].join(),
      guideReport: ['id', 'guideId -> guide.id', 'file'].join(),
      country: ['id', 'designation', 'code'].join(),
      systemConfiguration: ['id', 'version', 'text', 'type'].join(),
      update: ['++id', 'userId -> user.id', 'companyId -> company.id', 'date'].join(),
    });

    this.db
      .open()
      .then(async () => {
        await this.databaseSeedsService.runSeeds(this.db);
      })
      .catch(function (err: any) {
        console.error(err.stack || err);
      });
  }

  async resetDatabase() {
    return this.db.delete().then(() => this.initDb());
  }
}
