export class Country {
  id: number;
  designation: string;
  code: string;

  constructor(obj?: any) {
    this.id = obj?.id ?? null;

    this.designation = obj?.designation ?? null;
    this.code = obj?.code ?? null;
  }
}
