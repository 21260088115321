import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatabaseSeedsService {
  runSeeds(db: any) {
    return new Promise((resolve) => {
      db.transaction('rw', db.company, db.user, db.country, () => {
        //** COUNTRIES */
        db.country.bulkPut([
          { id: 14, code: 'AN', designation: 'Antilhas Holandesas' },
          { id: 71, code: 'US', designation: 'United States' },
          { id: 74, code: 'FJ', designation: 'Fiji' },
          { id: 81, code: 'GE', designation: 'Georgia' },
          { id: 65, code: 'EC', designation: 'Ecuador' },
          { id: 179, code: 'PT', designation: 'Portugal - Continente' },
          { id: 205, code: 'YU', designation: 'Sérvia' },
          { id: 242, code: 'PT-MA', designation: 'Portugal - Madeira' },
          { id: 243, code: 'PT-AC', designation: 'Portugal - Açores' },
          { id: 183, code: 'GB', designation: 'United Kingdom (EU before 2020)' },
          { id: 244, code: 'BL', designation: 'Saint Barthélemy' },
          { id: 192, code: 'RU', designation: 'Russian Federation' },
          { id: 126, code: 'JO', designation: 'Jordan' },
          { id: 222, code: 'TF', designation: 'French Southern Territories' },
          { id: 217, code: 'TW', designation: 'Taiwan' },
          { id: 241, code: 'ZW', designation: 'Zimbabwe' },
          { id: 13, code: 'AG', designation: 'Antigua and Barbuda' },
          { id: 117, code: 'ID', designation: 'Indonesia' },
          { id: 32, code: 'BO', designation: 'Bolivia, Plurinational State of' },
          { id: 159, code: 'NP', designation: 'Nepal' },
          { id: 8, code: 'DE', designation: 'Germany' },
          { id: 223, code: 'TG', designation: 'Togo' },
          { id: 144, code: 'MT', designation: 'Malta' },
          { id: 245, code: 'RS', designation: 'Serbia' },
          { id: 218, code: 'TJ', designation: 'Tajikistan' },
          { id: 189, code: 'RE', designation: 'Réunion' },
          { id: 59, code: 'DK', designation: 'Denmark' },
          { id: 128, code: 'LA', designation: "Lao People's Democratic Republic" },
          { id: 177, code: 'PL', designation: 'Poland' },
          { id: 31, code: 'BY', designation: 'Belarus' },
          { id: 178, code: 'PR', designation: 'Puerto Rico' },
          { id: 78, code: 'GA', designation: 'Gabon' },
          { id: 113, code: 'TC', designation: 'Turks and Caicos Islands' },
          { id: 160, code: 'NI', designation: 'Nicaragua' },
          { id: 191, code: 'RW', designation: 'Rwanda' },
          { id: 207, code: 'SY', designation: 'Syrian Arab Republic' },
          { id: 233, code: 'UY', designation: 'Uruguay' },
          { id: 165, code: 'NC', designation: 'New Caledonia' },
          { id: 246, code: 'VC', designation: 'Saint Vincent and the Grenadines' },
          { id: 53, code: 'KP', designation: "Korea, Democratic People's Republic of" },
          { id: 240, code: 'ZM', designation: 'Zambia' },
          { id: 138, code: 'MK', designation: 'Macedonia, the former Yugoslav Republic of' },
          { id: 22, code: 'AZ', designation: 'Azerbaijan' },
          { id: 36, code: 'BN', designation: 'Brunei Darussalam' },
          { id: 188, code: 'CM', designation: 'Cameroon' },
          { id: 66, code: 'ER', designation: 'Eritrea' },
          { id: 46, code: 'TD', designation: 'Chad' },
          { id: 16, code: 'DZ', designation: 'Algeria' },
          { id: 105, code: 'CC', designation: 'Cocos (Keeling) Islands' },
          { id: 103, code: 'NF', designation: 'Norfolk Island' },
          { id: 208, code: 'SO', designation: 'Somalia' },
          { id: 90, code: 'GY', designation: 'Guyana' },
          { id: 148, code: 'MR', designation: 'Mauritania' },
          { id: 168, code: 'NL', designation: 'Netherlands' },
          { id: 169, code: 'PW', designation: 'Palau' },
          { id: 115, code: 'VI', designation: 'Virgin Islands, U.S.' },
          { id: 27, code: 'BE', designation: 'Belgium' },
          { id: 110, code: 'MH', designation: 'Marshall Islands' },
          { id: 231, code: 'UA', designation: 'Ukraine' },
          { id: 232, code: 'UG', designation: 'Uganda' },
          { id: 135, code: 'LT', designation: 'Lithuania' },
          { id: 247, code: 'ME', designation: 'Montenegro' },
          { id: 28, code: 'BZ', designation: 'Belize' },
          { id: 152, code: 'MZ', designation: 'Mozambique' },
          { id: 147, code: 'MU', designation: 'Mauritius' },
          { id: 248, code: 'IM', designation: 'Isle of Man' },
          { id: 61, code: 'DM', designation: 'Dominica' },
          { id: 29, code: 'BJ', designation: 'Benin' },
          { id: 63, code: 'SV', designation: 'El Salvador' },
          { id: 198, code: 'LC', designation: 'Saint Lucia' },
          { id: 93, code: 'GQ', designation: 'Equatorial Guinea' },
          { id: 155, code: 'MN', designation: 'Mongolia' },
          { id: 234, code: 'UZ', designation: 'Uzbekistan' },
          { id: 41, code: 'CV', designation: 'Cape Verde' },
          { id: 249, code: 'JE', designation: 'Jersey' },
          { id: 182, code: 'KI', designation: 'Kiribati' },
          { id: 137, code: 'MO', designation: 'Macao' },
          { id: 10, code: 'AO', designation: 'Angola' },
          { id: 70, code: 'FM', designation: 'Micronesia, Federated States of' },
          { id: 75, code: 'PH', designation: 'Philippines' },
          { id: 130, code: 'LV', designation: 'Latvia' },
          { id: 186, code: 'CD', designation: 'Congo, the Democratic Republic of the' },
          { id: 104, code: 'KY', designation: 'Cayman Islands' },
          { id: 153, code: 'MD', designation: 'Moldova, Republic of' },
          { id: 60, code: 'DJ', designation: 'Djibouti' },
          { id: 174, code: 'PE', designation: 'Peru' },
          { id: 250, code: 'MF', designation: 'Saint Martin (French part)' },
          { id: 251, code: 'BQ', designation: 'Bonaire, Sint Eustatius and Saba' },
          { id: 89, code: 'GT', designation: 'Guatemala' },
          { id: 121, code: 'IS', designation: 'Iceland' },
          { id: 171, code: 'PG', designation: 'Papua New Guinea' },
          { id: 203, code: 'SN', designation: 'Senegal' },
          { id: 79, code: 'GM', designation: 'Gambia' },
          { id: 124, code: 'JM', designation: 'Jamaica' },
          { id: 52, code: 'CG', designation: 'Congo' },
          { id: 49, code: 'CY', designation: 'Cyprus' },
          { id: 173, code: 'PY', designation: 'Paraguay' },
          { id: 150, code: 'MX', designation: 'Mexico' },
          { id: 252, code: 'SX', designation: 'Sint Maarten (Dutch part)' },
          { id: 195, code: 'WS', designation: 'Samoa' },
          { id: 26, code: 'BH', designation: 'Bahrain' },
          { id: 98, code: 'HU', designation: 'Hungary' },
          { id: 23, code: 'BS', designation: 'Bahamas' },
          { id: 214, code: 'SR', designation: 'Suriname' },
          { id: 167, code: 'OM', designation: 'Oman' },
          { id: 58, code: 'CU', designation: 'Cuba' },
          { id: 54, code: 'KR', designation: 'Korea, Republic of' },
          { id: 139, code: 'MG', designation: 'Madagascar' },
          { id: 77, code: 'FR', designation: 'France' },
          { id: 197, code: 'SH', designation: 'Saint Helena, Ascension and Tristan da Cunha' },
          { id: 109, code: 'MP', designation: 'Northern Mariana Islands' },
          { id: 163, code: 'NU', designation: 'Niue' },
          { id: 34, code: 'BW', designation: 'Botswana' },
          { id: 237, code: 'VE', designation: 'Venezuela, Bolivarian Republic of' },
          { id: 97, code: 'HK', designation: 'Hong Kong' },
          { id: 96, code: 'HN', designation: 'Honduras' },
          { id: 106, code: 'CK', designation: 'Cook Islands' },
          { id: 122, code: 'IL', designation: 'Israel' },
          { id: 76, code: 'FI', designation: 'Finland' },
          { id: 11, code: 'AI', designation: 'Anguilla' },
          { id: 193, code: 'KN', designation: 'Saint Kitts and Nevis' },
          { id: 134, code: 'LI', designation: 'Liechtenstein' },
          { id: 108, code: 'FK', designation: 'Falkland Islands (Malvinas)' },
          { id: 57, code: 'HR', designation: 'Croatia' },
          { id: 50, code: 'CO', designation: 'Colombia' },
          { id: 56, code: 'CR', designation: 'Costa Rica' },
          { id: 239, code: 'WF', designation: 'Wallis and Futuna' },
          { id: 24, code: 'BD', designation: 'Bangladesh' },
          { id: 129, code: 'LS', designation: 'Lesotho' },
          { id: 141, code: 'MW', designation: 'Malawi' },
          { id: 51, code: 'KM', designation: 'Comoros' },
          { id: 111, code: 'UM', designation: 'United States Minor Outlying Islands' },
          { id: 180, code: 'KE', designation: 'Kenya' },
          { id: 5, code: 'AF', designation: 'Afghanistan' },
          { id: 196, code: 'AS', designation: 'American Samoa' },
          { id: 55, code: 'CI', designation: "Côte d'Ivoire" },
          { id: 164, code: 'NO', designation: 'Norway' },
          { id: 172, code: 'PK', designation: 'Pakistan' },
          { id: 99, code: 'YE', designation: 'Yemen' },
          { id: 235, code: 'VU', designation: 'Vanuatu' },
          { id: 253, code: 'AX', designation: 'Åland Islands' },
          { id: 39, code: 'BI', designation: 'Burundi' },
          { id: 145, code: 'MA', designation: 'Morocco' },
          { id: 7, code: 'AL', designation: 'Albania' },
          { id: 42, code: 'KH', designation: 'Cambodia' },
          { id: 73, code: 'ET', designation: 'Ethiopia' },
          { id: 170, code: 'PA', designation: 'Panama' },
          { id: 213, code: 'CH', designation: 'Switzerland' },
          { id: 146, code: 'MQ', designation: 'Martinique' },
          { id: 40, code: 'BT', designation: 'Bhutan' },
          { id: 225, code: 'TO', designation: 'Tonga' },
          { id: 216, code: 'TH', designation: 'Thailand' },
          { id: 212, code: 'SE', designation: 'Sweden' },
          { id: 238, code: 'VN', designation: 'Viet Nam' },
          { id: 254, code: 'GG', designation: 'Guernsey' },
          { id: 255, code: 'TL', designation: 'Timor-Leste' },
          { id: 158, code: 'NR', designation: 'Nauru' },
          { id: 114, code: 'VG', designation: 'Virgin Islands, British' },
          { id: 83, code: 'GI', designation: 'Gibraltar' },
          { id: 161, code: 'NE', designation: 'Niger' },
          { id: 95, code: 'HT', designation: 'Haiti' },
          { id: 142, code: 'MV', designation: 'Maldives' },
          { id: 107, code: 'FO', designation: 'Faroe Islands' },
          { id: 47, code: 'CL', designation: 'Chile' },
          { id: 211, code: 'SD', designation: 'Sudan' },
          { id: 120, code: 'IE', designation: 'Ireland' },
          { id: 64, code: 'AE', designation: 'United Arab Emirates' },
          { id: 48, code: 'CN', designation: 'China' },
          { id: 200, code: 'ST', designation: 'Sao Tome and Principe' },
          { id: 125, code: 'JP', designation: 'Japan' },
          { id: 227, code: 'TN', designation: 'Tunisia' },
          { id: 37, code: 'BG', designation: 'Bulgaria' },
          { id: 118, code: 'IR', designation: 'Iran, Islamic Republic of' },
          { id: 17, code: 'AR', designation: 'Argentina' },
          { id: 101, code: 'CX', designation: 'Christmas Island' },
          { id: 220, code: 'IO', designation: 'British Indian Ocean Territory' },
          { id: 15, code: 'SA', designation: 'Saudi Arabia' },
          { id: 162, code: 'NG', designation: 'Nigeria' },
          { id: 102, code: 'HM', designation: 'Heard Island and McDonald Islands' },
          { id: 119, code: 'IQ', designation: 'Iraq' },
          { id: 256, code: 'CW', designation: 'Curaçao' },
          { id: 38, code: 'BF', designation: 'Burkina Faso' },
          { id: 229, code: 'TR', designation: 'Turkey' },
          { id: 151, code: 'MM', designation: 'Myanmar' },
          { id: 221, code: 'PS', designation: 'Palestinian Territory, Occupied' },
          { id: 206, code: 'SG', designation: 'Singapore' },
          { id: 184, code: 'CF', designation: 'Central African Republic' },
          { id: 209, code: 'LK', designation: 'Sri Lanka' },
          { id: 215, code: 'SJ', designation: 'Svalbard and Jan Mayen' },
          { id: 116, code: 'IN', designation: 'India' },
          { id: 236, code: 'VA', designation: 'Holy See (Vatican City State)' },
          { id: 131, code: 'LB', designation: 'Lebanon' },
          { id: 224, code: 'TK', designation: 'Tokelau' },
          { id: 92, code: 'GN', designation: 'Guinea' },
          { id: 157, code: 'NA', designation: 'Namibia' },
          { id: 82, code: 'GS', designation: 'South Georgia and the South Sandwich Islands' },
          { id: 18, code: 'AM', designation: 'Armenia' },
          { id: 85, code: 'GR', designation: 'Greece' },
          { id: 230, code: 'TV', designation: 'Tuvalu' },
          { id: 228, code: 'TM', designation: 'Turkmenistan' },
          { id: 84, code: 'GD', designation: 'Grenada' },
          { id: 187, code: 'DO', designation: 'Dominican Republic' },
          { id: 201, code: 'EH', designation: 'Western Sahara' },
          { id: 132, code: 'LR', designation: 'Liberia' },
          { id: 154, code: 'MC', designation: 'Monaco' },
          { id: 123, code: 'IT', designation: 'Italy' },
          { id: 30, code: 'BM', designation: 'Bermuda' },
          { id: 62, code: 'EG', designation: 'Egypt' },
          { id: 176, code: 'PF', designation: 'French Polynesia' },
          { id: 43, code: 'CA', designation: 'Canada' },
          { id: 156, code: 'MS', designation: 'Montserrat' },
          { id: 210, code: 'SZ', designation: 'Swaziland' },
          { id: 185, code: 'CZ', designation: 'Czech Republic' },
          { id: 87, code: 'GP', designation: 'Guadeloupe' },
          { id: 194, code: 'PM', designation: 'Saint Pierre and Miquelon' },
          { id: 202, code: 'SC', designation: 'Seychelles' },
          { id: 175, code: 'PN', designation: 'Pitcairn' },
          { id: 199, code: 'SM', designation: 'San Marino' },
          { id: 91, code: 'GF', designation: 'French Guiana' },
          { id: 219, code: 'TZ', designation: 'Tanzania, United Republic of' },
          { id: 80, code: 'GH', designation: 'Ghana' },
          { id: 9, code: 'AD', designation: 'Andorra' },
          { id: 33, code: 'BA', designation: 'Bosnia and Herzegovina' },
          { id: 45, code: 'KZ', designation: 'Kazakhstan' },
          { id: 127, code: 'KW', designation: 'Kuwait' },
          { id: 143, code: 'ML', designation: 'Mali' },
          { id: 166, code: 'NZ', designation: 'New Zealand' },
          { id: 86, code: 'GL', designation: 'Greenland' },
          { id: 20, code: 'AU', designation: 'Australia' },
          { id: 133, code: 'LY', designation: 'Libya' },
          { id: 190, code: 'RO', designation: 'Romania' },
          { id: 19, code: 'AW', designation: 'Aruba' },
          { id: 69, code: 'ES', designation: 'Spain' },
          { id: 112, code: 'SB', designation: 'Solomon Islands' },
          { id: 136, code: 'LU', designation: 'Luxembourg' },
          { id: 21, code: 'AT', designation: 'Austria' },
          { id: 35, code: 'BR', designation: 'Brazil' },
          { id: 67, code: 'SK', designation: 'Slovakia' },
          { id: 44, code: 'QA', designation: 'Qatar' },
          { id: 12, code: 'AQ', designation: 'Antarctica' },
          { id: 6, code: 'ZA', designation: 'South Africa' },
          { id: 25, code: 'BB', designation: 'Barbados' },
          { id: 88, code: 'GU', designation: 'Guam' },
          { id: 140, code: 'MY', designation: 'Malaysia' },
          { id: 226, code: 'TT', designation: 'Trinidad and Tobago' },
          { id: 204, code: 'SL', designation: 'Sierra Leone' },
          { id: 68, code: 'SI', designation: 'Slovenia' },
          { id: 100, code: 'BV', designation: 'Bouvet Island' },
          { id: 149, code: 'YT', designation: 'Mayotte' },
          { id: 181, code: 'KG', designation: 'Kyrgyzstan' },
          { id: 72, code: 'EE', designation: 'Estonia' },
          { id: 94, code: 'GW', designation: 'Guinea-Bissau' },
          { id: 257, code: 'ES-IC', designation: 'Canary Islands' },
          { id: 258, code: 'XK', designation: 'Kosovo' },
          { id: 259, code: 'GB-IC', designation: 'United Kingdom (NON-EU)' },
          { id: 260, code: 'XI', designation: 'Northern Ireland (EU)' },
          { id: 261, code: 'XI-IC', designation: 'Northern Ireland (NON-EU)' },
        ]);

        resolve(true);
      });
    });
  }
}
