import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { Company } from './models/company.models';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(protected databaseService: DatabaseService) {}

  findAll(pageSize: number, page: number, search: string, filters?: any, sort?: any): Promise<{ data: Company[] }> {
    return new Promise((resolve, reject) => {
      let where = {};

      this.databaseService.db.company
        // .where(where)
        // .with({})
        .toArray()
        .then((data: Company[]) => {
          resolve({ data });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  findOne(id: string): Company {
    return this.databaseService.db.company
      .where({ id })
      .toArray()
      .then((res: Company[]) => {
        return res[0] ?? null;
      })
      .catch((error: any) => {
        console.warn(`CompanyService ~ findOne:`, error);
        return null;
      });
  }

  async createOrUpdate(data: Company) {
    const aux = await this.findOne(data.id);

    if (aux) {
      await this.databaseService.db.company.update(data.id, data);
    } else {
      await this.databaseService.db.company.add(data);
    }
  }

  async create(data: Company) {
    await this.databaseService.db.company.add(data);
  }

  async update(id: string, data: any) {
    await this.databaseService.db.company.update(id, data);
  }
}
