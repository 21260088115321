import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UtilsService } from './core/service/utils.service';
import { Router, NavigationEnd } from '@angular/router';
import { VersionCheckService } from './core/service/version-check.service';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs';
import { UrlService } from './core/service/url.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  host: {
    '(window:resize)': 'onResize($event)',
  },
})
export class AppComponent implements OnInit, AfterViewInit {
  previousUrl: string = '';
  currentUrl: string = '';

  constructor(
    public versionCheckService: VersionCheckService,
    private router: Router,
    private urlService: UrlService,
    private swUpdate: SwUpdate,
    public utils: UtilsService
  ) {
    // if (this.swUpdate.isEnabled) {
    //   console.log('SW activated');
    //   this.swUpdate.checkForUpdate().then((newUpdate) => {
    //     if (newUpdate) {
    //       console.log('New update found');
    //       this.swUpdate.activateUpdate().then((updated) => {
    //         if (!updated) {
    //           console.log('Updated by sw');
    //           document.location.reload();
    //         } else {
    //           console.log('not Updated by sw');
    //           document.location.reload();
    //         }
    //       });
    //     } else {
    //       console.log('Current version');
    //     }
    //   });
    // }

    // if (!this.utils.iOS) {
    //   if (Notification.permission == 'granted') {
    //     navigator.serviceWorker.getRegistration().then((reg) => {
    //       reg?.showNotification('Teste notificação', {});
    //     });
    //   } else {
    //     Notification.requestPermission((status) => {
    //       alert(status);
    //     });
    //   }
    // }

    versionCheckService.initVersionCheck(environment.version_check_url);
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

        this.urlService.setPreviousUrl(this.previousUrl);
      });
  }

  ngAfterViewInit() {
    this.fixScrollHeight();

    const deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      localStorage.setItem('deviceId', this.utils.getUUID());
    }
  }

  onResize() {
    this.fixScrollHeight();
  }

  fixScrollHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
